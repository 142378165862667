import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
let NotActiveAccountModal = class NotActiveAccountModal extends AbstractModal {
};
NotActiveAccountModal = __decorate([
    Component({ name: 'NotActiveAccountModal' })
], NotActiveAccountModal);
export { NotActiveAccountModal };
export default NotActiveAccountModal;
